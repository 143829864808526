<template>
  <div class="out_box">
    <van-dialog v-model:show="show" title="标题" :showConfirmButton="false">
      <div class="list_box">
        <div class="wrap" v-for="item of data.records" :key="item">
          <div class="list_item">
            <p class="title">{{ '第' + item.days + '天' }}</p>
            <div class="content">
              <img v-if="item.days === 7" class="coin" src="@/assets/images/coin2.png" style="width:70px;" />
              <img v-else class="coin" src="@/assets/images/coin.png" />
              <div class="complete" v-show="data.last_check_in_days >= item.days">
                <div class="box">
                  <p class="text">已领取</p>
                </div>
              </div>
            </div>
            <div class="quantity">{{ item.amount + '￥' }}</div>
          </div>
        </div>
      </div>
      <img class="close" src="@/assets/images/close.png" @click="show = false" />
    </van-dialog>
    <div class="sign_btn" @click="openPopup()">签</div>
  </div>
</template>


<script>
import { getUserInfoSign, userClock } from '@/utils/api.js'
export default {
  name: "Signed",
  data() {
    return {
      show: false,
      data: {},
    }
  },
  methods: {
    openPopup() {
      this.show = true;
      getUserInfoSign().then(res => {
        this.data = res.data
        if(res.data.isCheckin) {
          this.$toast.success({
            message: '今日已签到！',
            duration: 1500
          })
        } else {
          this.doSign()
        }
      })
    },
    doSign() {
      userClock({ days: this.data.last_check_in_days + 1 }).then(res => {
        if(res.data.id) {
          this.$toast.success({
            message: '签到成功！',
            duration: 1500,
            onClose: () => {
              this.openPopup();
            }
          })
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .out_box {
    .list_box{
      padding: 0 8px;
      // position: fixed;
      // bottom: 50%;
      // left: 50%;
      // margin-left: -44%;
      // margin-bottom: -150px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      background: linear-gradient(to left, #5b94e9, #81bcf0);
      border-radius: 16px;
      z-index: 18;
      .wrap {
        flex-basis: 25%;
        padding: 0 4px;
        text-align: center;
        box-sizing: border-box;
        &:last-child {
          flex-grow: 2;
        }
      }
      .list_item {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #cfeafd;
        position: relative;
        .title {
          padding: 8px 0;
          color: white;
          background: linear-gradient(to left, #a483dd, #c89ff4);
        }
        .content {
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          .coin {
            width: 50px;
          }
          .complete {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.4);
            .box {
              width: 60px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              bottom: 44%;
              left: 50%;
              margin-bottom: -30px;
              margin-left: -30px;
              border: 3px solid #f4f4f4;
              border-radius: 50%;
              .text {
                font-size: 18px;
                white-space: nowrap;
                color: #f4f4f4;
                font-weight: bold;
                transform: rotate(-22deg);
              }
            }
          }
        }
        .quantity{
          padding: 4px 0;
        }
      }
    }
    .close {
      display: table;
      margin: 0 auto;
      position: absolute;
      bottom: -100px;
      left: 50%;
      margin-left: -20px;
      z-index: 4;
    }
    .sign_btn {
      position: fixed;
      bottom: 100px;
      right: 30px;
      z-index: 7;
      background-color: #ff634d;
      width: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      color: white;
      border-radius: 8px;
    }
    ::v-deep .van-popup {
      overflow: visible;
      .van-dialog__header {
        display: none;
      }
    }
  }
</style>
