<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item v-for="item in list" :key="item.id">
      <img :src="item.images" @click="goTo(item.url)" alt=""/>
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import { getBanner } from '@/utils/api.js'
export default {
  name: 'Banner',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      getBanner().then(res => {
        this.list = res.data
      })
    },
    goTo(url) {
      window.open(url)
    }
  }
}
</script>
<style lang="scss" scoped>
  .my-swipe {
    .van-swipe-item {
      line-height: 10px;
    }
    img {
      width: 100%;
      height: 190px;
      border-radius: 15px;
    }
  }
</style>
