<template>
  <div class="noticebar" v-if="announces.length">
    <div class="notice-img">
      <!-- <img class="notice-img" src="../../static/images/ic_notice.png" alt="" /> -->
      <van-icon name="bullhorn-o" size="20px" />
    </div>
    <ul class="notice-list">
      <li
        v-for="(item, index) in announces"
        :key="item.title"
        :class="!index && play ? 'toUp' : ''"
        @click="onNotice(item)"
      >
        {{ item.title }}
      
      </li>
    </ul>
    <div class="img-right">
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
import { getMessageList } from '@/utils/api.js'
export default {
  name: 'noticeBar',
  props: {
    announces: {
      type: Array,
      default() {
        return [
          {
            title: '暫無公告'
          }
        ]
      }
    }
  },
  data() {
    return {
      play: true // 是否开始播放
    }
  },
  activated() {
    setInterval(this.startPlay, 4000)
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getMessageList().then((res) => {
        // console.log(res, '111');
        this.announces = res.data
      })
    },
    // 控制文案轮播
    startPlay() {
      let that = this
      that.play = true //开始播放
      setTimeout(() => {
        that.announces.push(that.announces[0]) //将第一条数据塞到最后一个
        that.announces.shift() //删除第一条数据
        that.play = false //暂停播放
      }, 500)
    },

    // 点击"公告"跳转
    onNotice() {
      this.$router.push('/user/message')
      //   if (!url && url === '') return
      //   this.$emit('onInfoTo', {
      //     url,
      //     title: '资讯详情'
      //   })
    }
  }
}
</script>

<style lang="scss" scoped>
.noticebar {
  width: 100%;
  background: #fff;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
}
.toUp {
  transform: translateY(-20px);
  transition: all 0.5s linear;
}
.notice-list {
  display: block;
  list-style: none;
  width: 100%;
  overflow: hidden;
  height: 20px;
}
.notice-list li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notice-img {
  width: 24px;
  margin-right: 12px;
  text-align: center;
}
.img-right {
  width: 8px;
}
li {
  height: 20px;
  line-height: 20px;
}
</style>
